import React from 'react';
import { useLocation } from 'react-router-dom';
import Packages from './Packages';
import { useAuth } from "../../context/AuthContext";

function SelectPackage() {
  const { userInfo } = useAuth();
  const isAdmin = userInfo.labels.includes("admin");

  // Use userInfo to populate studentInfo
  const studentInfo = {
    userId: userInfo.userId || '',
    name: userInfo.name || '',
    educationLevel: userInfo.educationLevel || ''
  };

  console.log("userInfo in SelectPackage:", userInfo);
  console.log("studentInfo in SelectPackage:", studentInfo);

  return (
    <>
      <Packages studentInfo={studentInfo} educationLevel={studentInfo.educationLevel} />
    </>
  );
}

export default SelectPackage;