import React, { useState } from 'react';
import { Card, Button, Row, Col, Modal } from 'react-bootstrap';
import moment from 'moment-timezone';
import PaymentMethods from './PaymentMethods_2';
import './Packages.css';

const Packages = ({ studentInfo, educationLevel }) => {
    console.log("Received educationLevel:", educationLevel);
    console.log("studentInfo:", studentInfo);
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);

    const currentDatePlus30Days = moment().add(30, 'days').tz('Africa/Nairobi').format('YYYY-MM-DD HH:mm:ss.SSS');
    const expiryDate = moment.tz(currentDatePlus30Days, 'YYYY-MM-DD HH:mm:ss.SSS', 'Africa/Nairobi').toDate();

    const calculateDaysLeft = (endDate) => {
        const endMoment = moment.tz(endDate, 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'Africa/Nairobi');
        const currentMoment = moment.tz('Africa/Nairobi');
        return endMoment.diff(currentMoment, 'days');
    }

    const packages = {
        PLE: [
            {
                tier: 'PLE Starter Pack',
                points: 1000,
                price: 20000,
                staticDate: false,
                duration: 30,
                expiryDate: expiryDate,
                features: ['Valid for 30 days'],
            },
            {
                tier: 'PLE Limited Pack',
                points: 1000,
                price: 70000,
                staticDate: true,
                duration: calculateDaysLeft('2024-12-31T23:59:59.999+03:00'),
                expiryDate: moment('2024-12-31T23:59:59.999+03:00').toISOString(),
                features: ['Valid until 31st December, 2024'],
            },
            {
                tier: 'PLE Annual Pack',
                points: 10000,
                price: 100000,
                staticDate: false,
                duration: 366,
                expiryDate: moment().tz('Africa/Nairobi').add(366, 'days').toDate(),
                features: ['Valid for a year - 366 Days'],
            },
        ],
        UCE: [
            {
                tier: 'UCE Starter Pack',
                points: 2000,
                price: 30000,
                staticDate: false,
                duration: 45,
                expiryDate: moment().tz('Africa/Nairobi').add(45, 'days').toDate(),
                features: ['Valid for 45 days'],
            },
            {
                tier: 'UCE Premium Pack',
                points: 5000,
                price: 80000,
                staticDate: true,
                duration: calculateDaysLeft('2024-12-31T23:59:59.999+03:00'),
                expiryDate: moment('2024-12-31T23:59:59.999+03:00').toISOString(),
                features: ['Valid until 31st December, 2024'],
            },
            {
                tier: 'UCE Ultimate Pack',
                points: 15000,
                price: 120000,
                staticDate: false,
                duration: 366,
                expiryDate: moment().tz('Africa/Nairobi').add(366, 'days').toDate(),
                features: ['Valid for a year - 366 Days'],
            },
        ]
    };

    const handlePurchaseClick = (selectedPkg) => {
        setSelectedPackage(selectedPkg);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const currentPackages = packages[educationLevel] || packages.PLE;
    console.log("Selected packages:", currentPackages);

    return (
        <div className="justify-content-center" style={{ backgroundColor: 'hsl(240, 78%, 98%)' }}>
            <Row className="justify-content-md-center">
                <h2 className="text-center" style={{ paddingTop: '30px' }}>Choose Your {educationLevel} Package</h2>
                <div className="packages-container">
                    {currentPackages.map((pkg, idx) => (
                        <Col key={idx} md={3} className="mb-3">
                            <Card className={`text-center package-card ${idx === 1 ? "middle-card" : ""}`}>
                                <Card.Body className="card-body">
                                    <Card.Title className="package-tier" style={{ color: `${idx === 1 ? 'white' : ''}` }}>
                                        {pkg.tier}
                                    </Card.Title>
                                    <Card.Text className="package-price" style={{ color: `${idx === 1 ? 'white' : ''}` }}>
                                        UGX.{pkg.price}
                                    </Card.Text>
                                    <ul className="package-features">
                                        {pkg.features.map((feature, featureIdx) => (
                                            <li key={featureIdx} className="feature-item">
                                                {feature}
                                            </li>
                                        ))}
                                    </ul>
                                    <Button variant='outline-primary' className={`package-learn-more ${idx === 1 ? ' middle-button' : ''}`} onClick={() => handlePurchaseClick(pkg)}>
                                        Select Pack
                                    </Button>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </div>
            </Row>

            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Complete Your Purchase</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedPackage && (
                        <PaymentMethods
                            price={selectedPackage.price}
                            points={selectedPackage.points}
                            tier={selectedPackage.tier}
                            paymentFor={'points'}
                            studentInfo={studentInfo}
                            duration={selectedPackage.duration}
                            expiryDate={selectedPackage.expiryDate}
                            staticDate={selectedPackage.staticDate}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default Packages;