// StudentDashboard.js
import React, { useState, useEffect } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faEdit } from "@fortawesome/free-solid-svg-icons";
import RecentResults from "../RecentResults";
import UceRecentResults from "../UceRecentResults";
import { useNavigate } from "react-router-dom";
import { getTransformedResults } from "../../utilities/resultsUtil";
import { useAuth } from "../../context/AuthContext";

const StudentDashboard = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const [results, setResults] = useState([]);
  // console.log(results);
  // console.log(userInfo);

  const isUce = userInfo.educationLevel === "UCE";

  //To view student results
  function viewResults(
    subjectName,
    totalMarks,
    totalPossibleMarks,
    attemptDate,
    qtnId,
    isUce // Add this parameter to check for UCE exams
  ) {
    // console.log("isUCE:", isUce);

    // Check if the exam is a UCE exam
    if (isUce) {
      navigate("/view-results", {
        state: {
          questionsData: undefined, // Or include actual data if available
          subjectName,
          totalMarks,
          totalPossibleMarks,
          attemptDate,
          qtnId,
        },
      });
    } else if (subjectName === "English Language") {
      // If it's an English Language exam, navigate to /exam-results
      navigate("/exam-results", { state: { qtnId } });
    } else {
      // For other cases, navigate to /answers
      navigate("/answers", {
        state: {
          questionsData: undefined, // Or include actual data if available
          subjectName,
          totalMarks,
          totalPossibleMarks,
          attemptDate,
          qtnId,
        },
      });
    }
  }

  useEffect(() => {
    const userId = userInfo?.userId;
    if (userId) {
      const transformedData = getTransformedResults(userId);
      if (JSON.stringify(transformedData) !== JSON.stringify(results)) {
        setResults(transformedData);
      }
    }
  }, [userInfo, results]); // Only re-run the effect if userInfo or results change

  const attemptExam = () => {
    navigate("/exam-page");
  };

  return (
    <>
      <Row>
        {isUce ? (
          <UceRecentResults
            results={results}
            onViewResults={(
              subjectName,
              totalMarks,
              totalPossibleMarks,
              attemptDate,
              qtnId
            ) =>
              viewResults(
                subjectName,
                totalMarks,
                totalPossibleMarks,
                attemptDate,
                qtnId,
                isUce
              )
            }
          />
        ) : (
          <RecentResults results={results} onViewResults={viewResults} />
        )}
        <Col lg={6}>
          <Card className="mb-4 shadow">
            <Card.Body>
              <Card.Title>Actions</Card.Title>
              <Button
                variant="success"
                className="me-2"
                onClick={() => navigate("/all-results")}
              >
                <FontAwesomeIcon icon={faChartLine} /> View All Results
              </Button>
              <Button variant="warning" onClick={attemptExam}>
                <FontAwesomeIcon icon={faEdit} /> Attempt Exam
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default StudentDashboard;
