import React, { useState, useEffect } from "react";
import { useUceQuiz } from "../../context/uceQuizContext";
import { Card, Form, ButtonGroup, Button } from "react-bootstrap";
import DOMPurify from "dompurify";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import katex from "katex";
import "katex/dist/katex.min.css";

const UcePracticals = ({ selectedQuestions, subjectName, examID }) => {
  const handleOptionChange = (
    e,
    questionId,
    subQuestionId,
    categoryId,
    selectedScore,
    examID,
    subjectName
  ) => {
    const selectedValue = e.target.value;

    // saveUserAnswer(
    //   examID,
    //   subjectName,
    //   questionId,
    //   subQuestionId,
    //   selectedValue,
    //   categoryId,
    //   selectedScore,
    //   correctAnswer
    // );
  };

  const renderMathHtml = (response) => {
    const formattedResponse = response.replace(/\$(.*?)\$/g, (match, p1) => {
      try {
        return katex.renderToString(p1, { displayMode: true });
      } catch (e) {
        return match;
      }
    });

    return { __html: DOMPurify.sanitize(formattedResponse) };
  };

  const renderHTML = (html) => (
    <div dangerouslySetInnerHTML={renderMathHtml(html)} />
  );

  const renderQuestion = (category, questionIndex, categoryId) => (
    <div key={questionIndex}>
      {category.instructions && (
        <Card.Title
          style={{
            marginTop: "20px",
            gap: "10px",
            display: "flex",
          }}
        >
          instructions --{renderHTML(category.instructions)}
        </Card.Title>
      )}

      <Card.Title
        style={{
          marginTop: "20px",
          padding: "20px",
          backgroundColor: "white",
          display: "grid",
          gridTemplateColumns: "auto auto",
          gap: "21px",
        }}
      >
        Qn {questionIndex + 1}.{" "}
        {category.question && renderHTML(category.question)}
      </Card.Title>

      {Array.isArray(category.sub_questions) &&
        category.sub_questions.map((subQuestion, subIdx) => (
          <div
            key={subQuestion.id}
            style={{
              backgroundColor: "white",
              padding: "20px",
              gap: "21px",
              paddingLeft: "4rem",
              border: "1px solid lightgray",
              borderRadius: "5px",
            }}
          >
            <Card.Subtitle
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                backgroundColor: "white",
                gap: "21px",
                width: "fit-content",
              }}
            >
              <span>{String.fromCharCode(97 + subIdx)})&nbsp;</span>
              <span>{renderHTML(subQuestion.examQuestion)}</span>
            </Card.Subtitle>
            <Form.Control
              as="textarea"
              rows={3}
              onChange={(e) =>
                handleOptionChange(
                  e,
                  category.id,
                  subQuestion.id,
                  categoryId,
                  0, // Replace 0 with actual score if needed
                  examID,
                  subjectName
                )
              }
              name={`question_${questionIndex}_sub_${subIdx}`}
              style={{ marginTop: "10px" }}
            />
          </div>
        ))}
    </div>
  );

  return (
    <MathJaxContext>
      {selectedQuestions?.map((category, index) =>
        renderQuestion(category.questions, index, category.category)
      )}
    </MathJaxContext>
  );
};

export default UcePracticals;
